import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W001 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 001: Clarifying a Major Electrical Misunderstanding" />
    <div className="container container-last">
      <h1>
        Webinar 001:
        <br />
        Clarifying a Major Electrical Misunderstanding
      </h1>
      <p className="meta">Watch this webinar first. Other webinars will build off of it.</p>
      <p>
        Electrical misunderstandings are based on assumptions due to incorrect electrical
        understanding. Misunderstandings develop from work experiences attempting to comprehend the
        complexities of working on electrical problems without proper electrical training.
      </p>
      <p>
        Over time, misconceptions grow into a false sense of reality. Misunderstandings waste time
        and money, create confusion and frustration because the real world of electricity doesn't
        work the way some think it does.
      </p>
      <p>
        If you get frustrated doing electrical work and don't look forward to electrical problems
        this misunderstanding is probably the main reason.
      </p>
      <p>
        Revealed in Webinar 001 "A Major Electrical Misunderstanding" is the a cause of one of the
        biggest problems most technicians have with comprehending electrical training, like these
        webinars, and then performing electrical work.
      </p>
      <p>
        This misunderstanding prevents techs from enjoying electrical work and making money at it.
        This first webinar is a "need-to-understand" to benefit the most from Vince's future
        electrical webinars and your success doing electrical work.
      </p>
      <p>
        The benefit from this first webinar is clarification of the misunderstanding to avoid
        confusion for this series of electrical training webinars.
      </p>
      <p className="boost">Get ready for an electrical revelation.</p>
      <iframe
        title="Webinar 001"
        src="https://player.vimeo.com/video/589526760"
        width="100%"
        height="500"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
      <p>
        Download the{' '}
        <a target="_blank" rel="noopener noreferrer" href="/Handout_W001.pdf">
          W-001 Handout PDF
        </a>{' '}
        and follow along with the webinar.
      </p>
      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W001
